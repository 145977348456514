@import "./components/home/home.scss";

@font-face {
  font-family: 'hkgrotesk light';
  src: local("hkgrotesk light"),
    url("./assets/fonts/hkgrotesk-light.woff2") format("truetype");
}

@font-face {
  font-family: 'hkgrotesk medium';
  src: local("hkgrotesk medium"),
    url("./assets/fonts/hkgrotesk-medium.woff2") format("truetype");
}

@font-face {
  font-family: 'Apple Chancery';
  src: local("Apple Chancery"),
    url("./assets/fonts/Apple_Chancery_100.ttf") format("truetype");
}

@font-face {
  font-family: 'ProximaNova Regular';
  src: local("ProximaNova Regular"),
    url("./assets/fonts/ProximaNova-Regular.otf") format("truetype");
}

@font-face {
  font-family: 'ProximaNova Medium';
  src: local("ProximaNova Medium"),
    url("./assets/fonts/ProximaNova-Medium.otf") format("truetype");
}

@font-face {
  font-family: 'Amiri Bold';
  src: local("Amiri Bold"),
    url("./assets/fonts/Amiri-Bold.ttf") format("truetype");
}

body {
  font-family: 'hkgrotesk light';
}

.nivo-header-style-one {
  .others-option {
    margin: 0 10px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
  }

  .others-options {
    margin: 0 10px;
  }

  .navbar-area {
    .logo {
      img {
        height: 70px !important;
      }
    }

    #navbarSupportedContent {
      position: absolute;
      top: 0;
      right: 20px;
      top: 9px;
    }

    .nivo-nav-one {
      .main-nav {
        padding: 15px 0;
        background-color: #081d3a !important;

        .navbar-nav {
          .nav-item a {
            color: #fff !important;
          }
        }

        .language-nav {
          a {
            margin-left: 5px !important
          }
        }
      }

      .search-icon {
        height: 25px;
      }
    }

    .mobile-nav {
      .mean-bar {
        .nav-div {
          .bar-icons {
            position: absolute;
            right: 15px;
            font-size: 30px;
            top: 30px;
          }
        }

        .close-div {
          span {
            position: absolute;
            color: #fff;
            right: 10px;
            font-size: 30px;
            top: 11px;
          }
        }

        .mean-nav {
          .navbar-nav {
            height: auto;

            .mob-font {
              font-family: "Amiri", serif;
              font-weight: 600;
              padding: calc(100vw/60*2) calc(100vw/60*4);
              border-bottom: 1px solid rgba(0, 0, 0, .15);
              font-size: 6.4vw;
              letter-spacing: -.02458em;
              line-height: 1.2;

              a {
                padding: 0;
                border-top: none;
                color: #000;
              }

              .expand-icon {
                position: absolute;
                top: 20px;
                right: 15px;
                color: #fe404e;
                font-size: 25px;
              }
            }

            .logo {
              padding: 15px 5px;

              img {
                height: 40px !important;
              }
            }
          }
        }
      }
    }
  }
}

.footer-top-area {
  padding-top: 30px;

  .ic {
    h3 {
      margin: 0;
      padding: 0;
      font-size: 35px;
      font-weight: normal;
      font-family: 'ProximaNova Regular';
    }
  }

  width: 100%;
  height: 100%;
  background-repeat:no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;
  background-image: url(./assets/img/footer_bg_with_gradient.png);
  opacity: 1;

  .logo {
    img {
      height: 70px !important;
    }
  }

  .single-widget {
    ul {

      li {
        font-family: 'ProximaNova Regular';
        font-size: 18px;
      }
    }

    h3 {
      border: none;
    }
  }

  .social-icons {
    border: 1px solid #fff;
    padding: 5px 12px;
    border-radius: 100%;
    text-align: center;

    .connect {
      font-family: 'ProximaNova Medium' !important;
      font-size: 20px;
    }
  }

  .social-icons .icons {
    font-size: 20px;
  }

  .signup-icon {
    height: 20px;
    margin-bottom: 0 !important;
  }

  .iccy {
    text-align: center;
  }

  .cy {
    display: block;
    font-size: 30px;
  }

  .AppleChancery {
    font-family: 'Apple Chancery';
  }

  .mail-txt {
    font-family: 'ProximaNova Regular';
    font-size: 18px;
    margin: 0 !important;
    white-space: nowrap;
  }

  .subscribe-submit {
    padding: 10px 20px;
  }

  @media only screen and (max-width: 991px) {
    .center-content {
      display: flex;
      justify-content: center;
    }

    #footer-logo {
      display: none;
    }

    .single-widget {

      margin-bottom: 10px !important;
      width: 100%;
      /* display: flex; */
      text-align: center;

      ul {
        li {
          border-bottom: 1px dotted;

          a {
            padding-bottom: 3px;
          }
        }
      }

      ul.social-icon {
        .connect {
          display: none;
        }

        li {
          border: none;

          a.social-icons {
            border: none;
          }
        }

      }
    }

    .subscribe-submit {
      position: absolute;
      margin-top: 0px;
    }

    .single-widget.iccy {
      .ic {
        h3 {
          font-size: 22px;
        }
      }

      .cy {
        font-size: 22px;
      }
    }

    .single-widget {
      .widget.subscribe {
        input {
          height: 38px;
        }

        .search-submit {
          height: 38px;
          padding: 0px 7px;
          font-size: 12px;
        }
      }


      .social-icon {
        display: flex;
        justify-content: center;

        .connect {
          margin-top: 5px;
        }

        .social-icons {
          padding: 5px 11px;

          /* .icons {
            font-size: 15px;
          } */
        }
      }


      ul {
        li {
          font-size: 12px;
        }
      }

      .mail-txt {
        font-size: 12px;
      }

      .logo {
        img {
          height: 80px !important;
        }
      }
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    .offset-25 {
      margin-left: 25%;
      margin-top: 20px;
    }
  }
}

.latest-news-area {
  .content {
    border: 1px solid #dddddd;
    padding: 20px;
    overflow: hidden;
    cursor: pointer;
  }

  .content img {
    margin-right: 15px;
    float: left;
    width: 142.5px;
    height: 142.5px;
  }

  .content h3,
  .content p {
    margin-left: 15px;
    display: block;
    margin: 2px 0 0 0;
  }
}

.app-main {
  overflow: hidden;

  main {
    margin-top: 116px;
  }

  @media only screen and (max-width: 768px) {
    main {
      margin-top: 80px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .mobile-nav .logo {
    top: 7px !important;
  }

  .mean-container {
    .mean-bar {
      background-color: #081d3a;
      padding: 0;
    }

    a.meanmenu-reveal span {
      background-color: #fff !important;
    }
  }
}