.home-main {
  .slider-section {
    .banner-info {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      pointer-events: none;
      cursor: pointer;

      .inner {
        position: relative;
        padding: 1.66em 3em;
        bottom: -40%;
        /* width: 600px;
        left: -20px; */

        h1,
        h2 {
          color: #fff;
          font-size: 36px;
          line-height: 1.1;
          margin-bottom: 10px;
          font-weight: normal;
          text-transform: uppercase;
          font-family: 'Amiri Bold';
        }

        .para-desc {
          color: #eee;
          font-size: 18px;
          line-height: 1.5;
          font-family: 'hkgrotesk medium';
          width: 600px;
          // font-family: Verdana, Geneva, Tahoma, sans-serif;
          //font-family: 'Open Sans', sans-serif
        }

        .cov-lft-bord {
          text-align: center;
          /*  padding-left: 20px;
          border-left: 4px solid #ff9b00; */
        }

        @media only screen and (min-width: 320px) and (max-width:630px) {
          h2 {
            font-size: 18px;
          }

          .para-desc {
            width: auto;
            font-size: 12px;
          }
        }
      }
    }
  }

  #central-focus {
    .section-title {
      h2 {
        font-family: 'hkgrotesk light' !important;
        font-weight: 300;
        text-transform: uppercase;
      }
    }

    .single-good-causes {
      padding: 0;

      /* img {
        width: 100%;
        height: calc(100vw/80*30);
      } */
    }

    .text {
      position: absolute;
      top: 0;

      h3 {
        text-align: center;
        color: #fff
      }

      p {
        padding: 0 20px;
        color: #fff;
        line-height: 18px;
      }

      a {
        display: block;
        text-align: center;

        &::before {
          content: none;
        }
      }

      img {
        width: 150px;
        display: block;
        margin: 0 auto;
      }
    }
  }

  .latest-news-area {
    .section-title {
      h2 {
        font-family: 'hkgrotesk light' !important;
        font-weight: 300;
        text-transform: uppercase;
      }
    }

    .news {
      a {
        color: #000 !important;
      }
    }
  }
}